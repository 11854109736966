* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #4c4c4c;
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
}

body:has(> .loader.false) {
    overflow: hidden;
}

body:has(> .productCard:hover) {
    overflow: hidden;
}

body:has(.productCard.open) .desktopFooter {
    display: none;
}

.loader {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: #fff;
    transition: 1s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader svg {
    width: 25vw;

}

.loader.true {
    transform: translateY(-100%);
}

.error {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.error .logo {
    width: 25vw;
    margin-bottom: 24px;
}

.error p {
    margin-bottom: 24px;
}

.desktopHeader {
  width: 100%;
  height: 100px;
  padding: 20px auto;
  background: #ffffff;
  display: grid;
  position: fixed;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(76, 76, 76, 0.2) 0px 4px 35px 0;
  z-index: 99;
}
.desktopHeader .logo {
  height: 60px;
  transition: 0.2s ease;
}
.desktopHeader .logo:hover {
  height: 65px;
}
.desktopHeader .langChanger {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  background: #ffffff;
  border: none;
  height: 100px;
  aspect-ratio: 1;
  font-size: 1rem;
  color: #9d2b2b;
  font-weight: bold;
  transition: 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.desktopHeader .langChanger:hover {
  background: #9d2b2b;
  color: #ffffff;
  cursor: pointer;
}
.desktopHeader .langMenu {
  position: absolute;
  top: 100px;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
  width: 100px;
  transition: 0.4s ease;
  transform: translateX(100%);
}
.desktopHeader .langMenu.true {
  transform: translateX(0%);
}
.desktopHeader .langMenu.true .selectLang {
  transform: translateX(0%);
}
.desktopHeader .langMenu[data-selected=en] .en {
  display: none;
}
.desktopHeader .langMenu[data-selected=en] .tr {
  transition-delay: 0.1s, 0s;
}
.desktopHeader .langMenu[data-selected=en] .ru {
  transition-delay: 0.2s, 0s;
}
.desktopHeader .langMenu[data-selected=tr] .tr {
  display: none;
}
.desktopHeader .langMenu[data-selected=tr] .en {
  transition-delay: 0.1s, 0s;
}
.desktopHeader .langMenu[data-selected=tr] .ru {
  transition-delay: 0.2s, 0s;
}
.desktopHeader .langMenu[data-selected=ru] .ru {
  display: none;
}
.desktopHeader .langMenu[data-selected=ru] .en {
  transition-delay: 0.1s, 0s;
}
.desktopHeader .langMenu[data-selected=ru] .tr {
  transition-delay: 0.2s, 0s;
}
.desktopHeader .langMenu .selectLang {
  width: 100%;
  height: 50px;
  font-size: 16px;
  background-color: #ffffff;
  border: none;
  transition-property: transform, background-color;
  transition-duration: 0.2s, 0.2s;
  transition-delay: 0.1s, 0s;
  transform: translateX(100%);
}
.desktopHeader .langMenu .selectLang:hover {
  background-color: #9d2b2b;
  cursor: pointer;
}

.desktopNav {
  position: fixed;
  top: 0;
  width: 72px;
  height: 100vh;
  overflow: hidden;
  background: rgba(157, 43, 43, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.3s ease;
  z-index: 98;
}
.desktopNav:hover {
  width: 250px;
  background: #9d2b2b;
}
.desktopNav:hover .divider {
  width: 200px;
}
.desktopNav .link {
  display: flex;
  width: 250px;
  overflow-wrap: normal;
  overflow: hidden;
  padding: 16px 20px;
  color: #ffffff;
  text-decoration: none;
  font-size: 1.4rem;
  align-items: center;
  transition: 0.3s ease;
}
.desktopNav .link:hover {
  background: #ffffff;
  color: #9d2b2b;
}
.desktopNav .link:hover .icon path {
  transition: 0.3s ease;
  fill: #9d2b2b;
}
.desktopNav .link .icon {
  margin: 0 36px 0 0;
  height: 30px;
  aspect-ratio: 1;
}
.desktopNav .link .icon path {
  fill: #ffffff;
}
.desktopNav .divider {
  width: 32px;
  height: 1px;
  background: #ffffff;
  margin: 0px 20px;
  transition: 0.3s ease;
}

.desktopFooter {
  width: 100%;
  box-shadow: rgba(76, 76, 76, 0.2) 0px -4px 35px 0;
  background: #ffffff;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 32px 16vw;
  grid-gap: 32px;
  gap: 32px;
  align-items: center;
  justify-content: center;
}
.desktopFooter .disclaimer {
  display: flex;
  align-items: center;
  font-size: 1rem;
  gap: 16px;
  width: -moz-fit-content;
  width: fit-content;
}
.desktopFooter .disclaimer .logo {
  height: 45px;
  grid-auto-columns: 1fr;
}
.desktopFooter .disclaimer img {
  height: 45px;
  width: 196.6242038217px;
  aspect-ratio: 4.3694267516;
}
.desktopFooter .workingHours {
  text-align: right;
}
.desktopFooter .workingHours h4 {
  color: #9d2b2b;
  font-weight: 500;
  font-size: 1.5rem;
}
.desktopFooter .workingHours .dayRow {
  color: #4c4c4c;
}
.desktopFooter .workingHours .dayRow span {
  font-weight: 500;
}
.desktopFooter .workingHours .detail {
  font-size: 0.75rem;
}
.desktopFooter .contact h4 {
  color: #9d2b2b;
  font-weight: 500;
  font-size: 1.5rem;
}
.desktopFooter .contact p {
  display: flex;
  gap: 4px;
}
.desktopFooter .legal {
  overflow: hidden;
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 0.75rem;
}
.desktopFooter .legal a {
  text-decoration: none;
}
.desktopFooter .ad {
  overflow: hidden;
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
}
.desktopFooter .ad img {
  height: 32px;
  width: 32px;
}
.desktopFooter .ad .hkd {
  color: #0a192f;
}

.aboutDesktopLayout .banner {
  position: relative;
  height: 396px;
}
.aboutDesktopLayout .banner .title {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  text-align: center;
  margin: auto;
  top: 100px;
  bottom: 0;
  z-index: 4;
  color: #ffffff;
  font-size: 92px;
}
.aboutDesktopLayout .banner .bannerImg {
  width: 100%;
  height: auto;
  filter: brightness(0.6);
}
.aboutDesktopLayout .sectionChanger {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 48px;
  padding: 12px;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 20px;
}
.aboutDesktopLayout .sectionChanger a {
  text-decoration: none;
  transition: 0.3s ease;
}
.aboutDesktopLayout .sectionChanger a.active {
  font-weight: 700;
}

.productsDesktopLayout .bgImg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
  z-index: -1;
}

.homeDesktop {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.homeDesktop .content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  z-index: 2;
}
.homeDesktop .content .hero {
  color: #ffffff;
  font-size: 6rem;
  padding-left: 15vw;
  align-self: center;
  font-weight: 700;
}
.homeDesktop .content .cta {
  position: relative;
}
.homeDesktop .content .cta .productCta {
  font-size: 2rem;
  font-weight: 500;
  text-decoration: none;
  color: #ffffff;
  padding: 16px 32px;
  position: absolute;
  display: flex;
  top: calc(550px - 1rem - 64px - 1px);
  width: 300px;
  right: 150px;
  z-index: 3;
  border-bottom: 1px solid #ffffff;
  justify-content: space-between;
  transition: 0.3s ease;
}
.homeDesktop .content .cta .productCta:hover {
  background: #ffffff;
  color: #9d2b2b;
  cursor: pointer;
}
.homeDesktop .content .cta .productCta:hover svg path {
  fill: #9d2b2b;
}
.homeDesktop .content .cta .productCta svg path {
  transition: 0.3s ease;
  fill: #ffffff;
}
.homeDesktop .content .cta .ctaRect {
  position: absolute;
  right: 0;
  top: 100px;
  width: 450px;
  aspect-ratio: 1;
  background: rgba(157, 43, 43, 0.8);
}
.homeDesktop .content .cta .accentRect {
  position: absolute;
  background: rgba(157, 43, 43, 0.8);
  aspect-ratio: 1;
}
.homeDesktop .content .cta .accentRect.one {
  width: 175px;
  top: 600px;
  left: 0;
}
.homeDesktop .content .cta .accentRect.two {
  width: 75px;
  top: 200px;
  right: 600px;
}
.homeDesktop .content .cta .accentRect.three {
  width: 50px;
  top: 400px;
  right: 800px;
}
.homeDesktop .content .cta .accentRect.four {
  width: 50px;
  top: 800px;
  right: 500px;
}
.homeDesktop .content .cta .accentRect.five {
  width: 75px;
  top: 750px;
  right: 275px;
}
.homeDesktop .content .cta .accentRect.six {
  width: 50px;
  top: 650px;
  right: 100px;
}
.homeDesktop .filter {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
}
.homeDesktop .backgroundImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.aboutContent {
  width: 100%;
}
.aboutContent .desktopContent {
  font-size: 20px;
  margin: 48px auto 48px auto;
  width: clamp(500px, 45%, 1000px);
}
.aboutContent .desktopContent::first-letter {
  initial-letter: 3;
  font-weight: bold;
  margin-right: 8px;
  color: #9d2b2b;
}

.productsContent {
  width: 100%;
  padding-top: 172px;
  padding-left: 75px;
}
.productsContent .desktopProductsHeader {
  padding-left: 72px;
  margin-bottom: 72px;
}
.productsContent .desktopProductsHeader h2 {
  font-size: 96px;
  color: #ffffff;
  font-weight: bold;
  line-height: 100%;
}
.productsContent .desktopProductsHeader p {
  font-size: 20px;
  color: #ffffff;
  width: 400px;
  margin-left: 6px;
}
.productsContent .desktopProductsContent {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  margin-bottom: 72px;
}
.productsContent .desktopProductsContent .productCard {
  width: 400px;
  aspect-ratio: 1;
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-bottom: 72px;
  text-decoration: none;
  transition: 0.3s ease;
}
.productsContent .desktopProductsContent .productCard:hover {
  transform: scale(1.05);
}
.productsContent .desktopProductsContent .productCard .productInformation {
  position: absolute;
  bottom: 24px;
  left: 24px;
}
.productsContent .desktopProductsContent .productCard .productInformation .productTitle {
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
}
.productsContent .desktopProductsContent .productCard .productInformation .productDetails {
  font-size: 16px;
  color: #ffffff;
  width: 90%;
}
.productsContent .desktopProductsContent .productCard .cardBgImg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  filter: brightness(0.7);
}
.productsContent .desktopProductsContent .productCard .cardBgImg .cardBgImg {
  height: 100%;
  width: 45%;
  object-fit: cover;
}
.productsContent .desktopProductsContent .productCard.open {
  background: #ffffff;
  box-shadow: 0px 0px 50px 10px rgba(76, 76, 76, 0.2);
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 6;
  width: calc(100% - 92px);
  height: calc(100% - 120px);
  aspect-ratio: unset;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  grid-column-start: end;
  grid: auto-flow dense;
}
.productsContent .desktopProductsContent .productCard.open:hover {
  transform: scale(1);
}
.productsContent .desktopProductsContent .productCard.open .cardBgImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: unset;
  grid-column: 1/2;
  grid-row: 1/2;
}
.productsContent .desktopProductsContent .productCard.open .productInformation {
  position: unset;
  grid-column: 2/3;
  grid-row: 1/2;
  padding: 5% 15%;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .productTitle {
  font-size: 96px;
  font-weight: 500;
  color: #9d2b2b;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .productDetails {
  font-size: 20px;
  color: #4c4c4c;
  width: 110%;
  text-align: justify;
  margin-bottom: 48px;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .specificationList {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 24px;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .specificationList .specBtn {
  font-size: 20px;
  padding: 6px 18px;
  border-radius: 4px;
  text-decoration: none;
  border: 2px solid #9d2b2b;
  color: #4c4c4c;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .specificationList .specBtn.active {
  background: #9d2b2b;
  color: #ffffff;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .specificationTable {
  margin: 24px auto 0;
  font-size: 24px;
  width: 60%;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .specificationTable .headerRow {
  padding-bottom: 48px;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .specificationTable .material {
  text-align: right;
  padding: 8px 32px 8px 0;
  width: 33%;
  color: #9d2b2b;
  font-weight: 500;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .specificationTable .percentage {
  padding: 8px 0 8px 32px;
  width: 33%;
  color: #4c4c4c;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .specificationTable .typical {
  padding: 8px 0 8px 32px;
  width: 33%;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .specificationTable .spacer {
  display: flex;
  width: 301%;
  height: 1px;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .specificationTable .spacer span {
  background-color: #4c4c4c;
  height: 1px;
  width: 100%;
  display: flex;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .closeBtn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.productsContent .desktopProductsContent .productCard.open .productInformation .closeBtn svg path {
  fill: #9d2b2b;
}

.contactContent {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
}
.contactContent .title {
  color: #ffffff;
  font-size: 96px;
  grid-column: 1/2;
  width: 60%;
}
.contactContent .bgImg {
  position: absolute;
  z-index: -2;
  object-fit: cover;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(0 0, 60% 0, 40% 100%, 0% 100%);
          clip-path: polygon(0 0, 60% 0, 40% 100%, 0% 100%);
  filter: brightness(0.6);
  left: 0;
}
.contactContent .redParallelogram {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(58% 0, 62% 0, 42% 100%, 38% 100%);
          clip-path: polygon(58% 0, 62% 0, 42% 100%, 38% 100%);
  background: #9d2b2b;
  z-index: -1;
}
.contactContent .contactForm {
  display: flex;
  flex-direction: column;
  grid-column: 2/3;
  width: 40%;
  text-align: center;
  gap: 12px;
}
.contactContent .contactForm h2 {
  color: #9d2b2b;
  font-size: 48px;
}
.contactContent .contactForm .label-before, .contactContent .contactForm .field input:focus + label::before,
.contactContent .contactForm .field textarea:focus + label::before, .contactContent .contactForm .field input:valid + label::before,
.contactContent .contactForm .field textarea:valid + label::before {
  line-height: 20px;
  font-size: 12px;
  top: -10px;
  background: #fff;
  padding: 0 6px;
  left: 9px;
}
.contactContent .contactForm .field {
  position: relative;
  margin-bottom: 15px;
}
.contactContent .contactForm .field label::before {
  content: attr(title);
  position: absolute;
  top: 0;
  left: 15px;
  line-height: 40px;
  color: #777;
  transition: 300ms all;
}
.contactContent .contactForm .field input,
.contactContent .contactForm .field textarea {
  width: 100%;
  line-height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  color: #222;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
}
.contactContent .contactForm .field input:focus,
.contactContent .contactForm .field textarea:focus {
  outline: 0;
  border-color: #9d2b2b;
}
.contactContent .contactForm .field input:valid + label::before,
.contactContent .contactForm .field textarea:valid + label::before {
  content: attr(data-title);
}
.contactContent .contactForm .field input:focus + label::before,
.contactContent .contactForm .field textarea:focus + label::before {
  color: #9d2b2b;
}
.contactContent .contactForm .field textarea {
  height: 150px;
}
.contactContent .contactForm .submitBtn {
  background: #9d2b2b;
  color: #ffffff;
  border: 2px #9d2b2b solid;
  font-size: 16px;
  padding: 12px 24px;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border-radius: 4px;
  font-weight: 600;
  transition: 0.3s ease;
}
.contactContent .contactForm .submitBtn:hover {
  background: #ffffff;
  color: #9d2b2b;
  cursor: pointer;
}
.contactContent .contactForm .infoBox {
  margin-bottom: 12px;
}
.contactContent .contactForm .infoBox.success {
  color: green;
}
.contactContent .contactForm .infoBox.error {
  color: #9d2b2b;
}
.contactContent .contactForm .alternativeSolution {
  font-size: 14px;
}
.contactContent .contactForm .alternativeSolution .attention {
  color: #9d2b2b;
}

html:not([data-scroll="0"]) .mobileHeaderAndNav .header {
  background-color: #ffffff;
}
html:not([data-scroll="0"]) .mobileHeaderAndNav .header a .logo {
  filter: unset;
}
html:not([data-scroll="0"]) .mobileHeaderAndNav .header .hamburgerBtn .line {
  background: #9d2b2b;
}

.mobileHeaderAndNav {
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 99;
}
.mobileHeaderAndNav.colored .header {
  background-color: #ffffff;
  border-bottom: 1px solid #9d2b2b;
}
.mobileHeaderAndNav.colored .header a .logo {
  filter: unset;
}
.mobileHeaderAndNav.colored .header .hamburgerBtn .line {
  background: #9d2b2b;
}
.mobileHeaderAndNav .header {
  width: 100%;
  height: 98px;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  transition: 0.3s ease;
}
.mobileHeaderAndNav .header.open {
  background-color: #ffffff;
  border-bottom: 1px solid #9d2b2b;
}
.mobileHeaderAndNav .header a {
  height: 100%;
  width: auto;
}
.mobileHeaderAndNav .header a .logo {
  height: 50px;
  filter: brightness(0) invert(1);
  transition: 0.3s filter ease;
}
.mobileHeaderAndNav .header a .logo.open {
  filter: unset;
}
.mobileHeaderAndNav .header .hamburgerBtn {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  background: none;
  border: none;
}
.mobileHeaderAndNav .header .hamburgerBtn.open .line {
  background: #9d2b2b;
}
.mobileHeaderAndNav .header .hamburgerBtn.open .one {
  transform: rotate(-45deg) translate(-5px, 5px);
}
.mobileHeaderAndNav .header .hamburgerBtn.open .two {
  opacity: 0;
}
.mobileHeaderAndNav .header .hamburgerBtn.open .three {
  transform: rotate(45deg) translate(-5px, -5px);
}
.mobileHeaderAndNav .header .hamburgerBtn .line {
  width: 25px;
  height: 2px;
  background: #ffffff;
  display: flex;
  transition: 0.3s ease;
}
.mobileHeaderAndNav .navMenu {
  width: 100vw;
  height: calc(100vh - 98px);
  top: 98px;
  position: fixed;
  background: #ffffff;
  transform: translateX(-100%);
  opacity: 0.3;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
  overflow-y: scroll;
  z-index: 99;
}
.mobileHeaderAndNav .navMenu.open {
  transform: translateX(0%);
  opacity: 1;
}
.mobileHeaderAndNav .navMenu .mainNavButton {
  color: #9d2b2b;
  border: none;
  border-bottom: solid 1px #9d2b2b;
  width: 100%;
  display: flex;
  text-decoration: none;
  font-size: 24px;
  padding: 16px;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
}
.mobileHeaderAndNav .navMenu .dropdown {
  height: 75px;
  width: 100%;
  overflow: hidden;
  transition: 0.5s ease;
  display: flex;
  flex-direction: column;
}
.mobileHeaderAndNav .navMenu .dropdown svg {
  transition: 0.5s ease;
}
.mobileHeaderAndNav .navMenu .dropdown.open {
  height: 555px;
}
.mobileHeaderAndNav .navMenu .dropdown.open svg {
  transform: rotate(90deg);
}
.mobileHeaderAndNav .navMenu .dropdown .dropdownItem {
  font-size: 20px;
  line-height: 1.37;
  padding: 10px 32px;
  text-decoration: none;
  border: none;
  border-bottom: #4c4c4c solid 1px;
  background: #ffffff;
  color: #4c4c4c;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
}

.mobileFooter {
  width: 100vw;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  font-size: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0px -10px 10px 1px rgba(0, 0, 0, 0.1);
}
.mobileFooter .disclaimer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.mobileFooter .disclaimer .logo {
  height: 30px;
}
.mobileFooter .disclaimer img {
  height: 30px;
  width: 131.0828025478px;
  aspect-ratio: 4.3694267516;
}
.mobileFooter .workingHours {
  gap: 4px;
  display: flex;
  flex-direction: column;
}
.mobileFooter .workingHours h4 {
  font-size: 16px;
  color: #9d2b2b;
}
.mobileFooter .workingHours .dayRow {
  color: #4c4c4c;
}
.mobileFooter .workingHours .dayRow span {
  font-weight: 500;
}
.mobileFooter .workingHours .detail {
  font-size: 10px;
}
.mobileFooter .contact {
  gap: 4px;
  display: flex;
  flex-direction: column;
}
.mobileFooter .contact h4 {
  font-size: 16px;
  color: #9d2b2b;
}
.mobileFooter .contact p {
  display: flex;
  align-items: center;
  gap: 8px;
}
.mobileFooter .contact p svg {
  height: 17px;
  width: 17px;
  aspect-ratio: 1;
  fill: #9d2b2b;
}
.mobileFooter .legal {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.mobileFooter .legal .row {
  display: flex;
  justify-content: space-evenly;
}
.mobileFooter .legal .row a {
  text-decoration: none;
}
.mobileFooter .legal p {
  text-align: center;
}
.mobileFooter .ad {
  overflow: hidden;
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.mobileFooter .ad img {
  height: 32px;
  width: 32px;
}
.mobileFooter .ad .hkd {
  color: #0a192f;
}

.homeMobile {
  height: -moz-fit-content;
  height: fit-content;
  padding: 24px;
}
.homeMobile .hero {
  position: relative;
  padding-top: 98px;
  height: 450px;
}
.homeMobile .hero .title {
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 48px;
}
.homeMobile .hero .cta {
  font-size: 16px;
  color: #ffffff;
  background: #9d2b2b;
  padding: 8px 24px;
  border-radius: 4px;
  text-decoration: none;
}
.homeMobile .hero .mobileHomeBg {
  position: absolute;
  z-index: -1;
  top: -24px;
  left: -24px;
  width: 100vw;
  height: auto;
}
.homeMobile .content .subheader {
  color: #9d2b2b;
  font-size: 36px;
}
.homeMobile .content .info {
  font-size: 12px;
  color: #4c4c4c;
}
.homeMobile .content .mobileProductsCards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
  margin-top: 24px;
}
.homeMobile .content .mobileProductsCards .productCard {
  width: 100%;
  aspect-ratio: 1;
  background: #4c4c4c;
  position: relative;
  border-radius: 4px;
}
.homeMobile .content .mobileProductsCards .productCard h3 {
  position: absolute;
  color: #ffffff;
  z-index: 1;
  bottom: 12px;
  left: 12px;
  font-weight: 400;
}
.homeMobile .content .mobileProductsCards .productCard img {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
  position: absolute;
  filter: brightness(0.75);
  border-radius: 4px;
}

.productsMobile {
  position: relative;
  padding: 98px 24px 24px 24px;
}
.productsMobile .hero {
  height: 330px;
}
.productsMobile .hero .heroContent {
  width: 100%;
  height: 100%;
  padding-top: 24px;
}
.productsMobile .hero .heroContent h2 {
  color: #ffffff;
  font-size: 32px;
  margin-bottom: 12px;
}
.productsMobile .hero .heroContent p {
  color: #ffffff;
  font-size: 12px;
}
.productsMobile .hero img {
  width: 100vw;
  height: auto;
  filter: brightness(0.75);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.productsMobile .specifications .subHeading {
  font-size: 36px;
  color: #9d2b2b;
  padding-bottom: 32px;
}
.productsMobile .specifications .specificationList {
  padding: 8px 0 8px 0;
  width: 100%;
  flex-direction: row;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  gap: 8px;
}
.productsMobile .specifications .specificationList .specBtn {
  padding: 5px 14px;
  font-size: 14px;
  border-radius: 4px;
  text-decoration: none;
  border: 2px solid #9d2b2b;
  word-break: keep-all;
  width: -moz-fit-content;
  width: fit-content;
}
.productsMobile .specifications .specificationList .specBtn.active {
  background: #9d2b2b;
  color: #ffffff;
}
.productsMobile .specifications .specificationTable {
  width: 100%;
}
.productsMobile .specifications .specificationTable tr {
  width: 100%;
}
.productsMobile .specifications .specificationTable tr .material {
  text-align: right;
  padding: 8px 32px 8px 0;
  width: 33%;
  color: #9d2b2b;
  font-size: 16px;
  font-weight: 500;
}
.productsMobile .specifications .specificationTable tr .percentage {
  padding: 8px 0 8px 32px;
  width: 33%;
  color: #4c4c4c;
  font-size: 16px;
}
.productsMobile .specifications .specificationTable tr .typical {
  padding: 8px 0 8px 32px;
  width: 33%;
}
.productsMobile .specifications .specificationTable tr .spacer {
  display: flex;
  width: 301%;
  height: 1px;
}
.productsMobile .specifications .specificationTable tr .spacer span {
  background-color: #4c4c4c;
  height: 1px;
  width: 100%;
  display: flex;
}
.productsMobile .specifications .specificationTable tr:last-child .spacer {
  display: none;
}

.aboutMobile {
  height: -moz-fit-content;
  height: fit-content;
  padding: 24px;
}
.aboutMobile .hero {
  position: relative;
  padding-top: 128px;
  height: 420px;
}
.aboutMobile .hero .title {
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 48px;
}
.aboutMobile .hero .mobileAboutBg {
  position: absolute;
  z-index: -1;
  top: -24px;
  left: -24px;
  width: 100vw;
  height: auto;
  filter: brightness(0.75);
}
.aboutMobile .content h2 {
  color: #9d2b2b;
  font-size: 40px;
}
.aboutMobile .content p {
  text-indent: 16px;
  text-align: justify;
}
.aboutMobile .divider {
  width: 80%;
  text-align: center;
  height: 1px;
  background: #9d2b2b;
  display: flex;
  margin: 24px auto 12px;
}

.contactMobile {
  height: -moz-fit-content;
  height: fit-content;
  padding: 24px 24px 72px 24px;
}
.contactMobile .hero {
  position: relative;
  padding-top: 128px;
  height: 450px;
}
.contactMobile .hero .title {
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 48px;
}
.contactMobile .hero .cta {
  font-size: 16px;
  color: #ffffff;
  background: #9d2b2b;
  padding: 8px 24px;
  border-radius: 4px;
  text-decoration: none;
}
.contactMobile .hero .mobileHomeBg {
  position: absolute;
  z-index: -1;
  top: -24px;
  left: -24px;
  width: 100vw;
  height: auto;
}
.contactMobile .contactForm {
  display: flex;
  flex-direction: column;
  grid-column: 2/3;
  width: 100%;
  text-align: center;
  gap: 12px;
}
.contactMobile .contactForm h2 {
  color: #9d2b2b;
  font-size: 40px;
}
.contactMobile .contactForm .label-before, .contactMobile .contactForm .field input:focus + label::before,
.contactMobile .contactForm .field textarea:focus + label::before, .contactMobile .contactForm .field input:valid + label::before,
.contactMobile .contactForm .field textarea:valid + label::before {
  line-height: 20px;
  font-size: 12px;
  top: -10px;
  background: #fff;
  padding: 0 6px;
  left: 9px;
}
.contactMobile .contactForm .field {
  position: relative;
  margin-bottom: 15px;
}
.contactMobile .contactForm .field label::before {
  content: attr(title);
  position: absolute;
  top: 0;
  left: 15px;
  line-height: 40px;
  color: #777;
  transition: 300ms all;
}
.contactMobile .contactForm .field input,
.contactMobile .contactForm .field textarea {
  width: 100%;
  line-height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  color: #222;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
}
.contactMobile .contactForm .field input:focus,
.contactMobile .contactForm .field textarea:focus {
  outline: 0;
  border-color: #9d2b2b;
}
.contactMobile .contactForm .field input:valid + label::before,
.contactMobile .contactForm .field textarea:valid + label::before {
  content: attr(data-title);
}
.contactMobile .contactForm .field input:focus + label::before,
.contactMobile .contactForm .field textarea:focus + label::before {
  color: #9d2b2b;
}
.contactMobile .contactForm .field textarea {
  height: 150px;
}
.contactMobile .contactForm .submitBtn {
  background: #9d2b2b;
  color: #ffffff;
  border: 2px #9d2b2b solid;
  font-size: 16px;
  padding: 12px 24px 12px;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border-radius: 4px;
  font-weight: 600;
  transition: 0.3s ease;
}
.contactMobile .contactForm .submitBtn:hover {
  background: #ffffff;
  color: #9d2b2b;
  cursor: pointer;
}
.contactMobile .contactForm .infoBox {
  margin-bottom: 12px;
}
.contactMobile .contactForm .infoBox.success {
  color: green;
}
.contactMobile .contactForm .infoBox.error {
  color: #9d2b2b;
}
.contactMobile .contactForm .alternativeSolution {
  font-size: 14px;
}
.contactMobile .contactForm .alternativeSolution .attention {
  color: #9d2b2b;
}/*# sourceMappingURL=style.css.map */
@font-face {
font-family: '__excon_055a6f';
src: url(/_next/static/media/32a695882f7aa793-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__excon_Fallback_055a6f';src: local("Arial");ascent-override: 93.37%;descent-override: 24.01%;line-gap-override: 8.89%;size-adjust: 112.46%
}.__className_055a6f {font-family: '__excon_055a6f', '__excon_Fallback_055a6f'
}

