* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #4c4c4c;
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
}

body:has(> .loader.false) {
    overflow: hidden;
}

body:has(> .productCard:hover) {
    overflow: hidden;
}

body:has(.productCard.open) .desktopFooter {
    display: none;
}

.loader {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: #fff;
    transition: 1s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader svg {
    width: 25vw;

}

.loader.true {
    transform: translateY(-100%);
}

.error {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.error .logo {
    width: 25vw;
    margin-bottom: 24px;
}

.error p {
    margin-bottom: 24px;
}
